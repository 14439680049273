<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#005f32"
      spinner="bar-fade-scale" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <!-- <v-layout wrap>
      <v-flex xs12 py-10 class="popregular" style="color: black; font-size: 40px">
        Wild India needs your help</v-flex>
    </v-layout> -->
    <v-layout wrap justify-center pt-10>
      <v-flex xs10 pb-9>
        <v-layout wrap
        justify-end>
      <v-flex xs2>
        <v-btn color="#e27725">
<span style="color:white" class="popregular medium">
  EMAIL SUBSCRIPTION
</span>

        </v-btn>
      </v-flex>
      <v-flex xs1 pl-6>
        <a href="#faq-section">
        <v-btn  color="#e27725">
          <span class="popregular medium" style="color:white">
            FAQ'S
          </span>
         </v-btn>
</a>
      </v-flex>
      </v-layout>
      </v-flex>
      <v-flex xs12 md7>
        <v-layout wrap>
          <v-flex xs12>
            <v-img src="../assets/Images/homeimage.jpg"></v-img>
          </v-flex>
          <v-flex xs12 text-left pt-8 pb-6 >
            <span class="popregular medium" style="color: black">By supporting Wildlife Trust of India, you help protect
              endangered animals, provide emergency relief to animals in
              distress, and help prevent the destruction of critical wild
              habitats.</span>
          </v-flex>
          <v-flex xs12 px-2 pt-8 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
              At WTI, we offer a
              <span  style="font-weight:bold;">

                second chance at life
              </span>
              to wild animals that are orphaned at a very
              young age. After losing parental care, it becomes nearly impossible for these young
              animals to survive on their own in the wild.



          </v-flex>

          <v-flex xs12 px-2 pt-8 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
            <span>


              Our joy knows no bounds when rehabilitated individuals
              <span style="text-decoration: underline; cursor: pointer;" @click="navigateToRehabitation">
                successfully adapt to their natural environment
              </span>

              and even give birth to their own offspring in the wild.
            </span>
          </v-flex>

          <v-flex xs12 px-2 pt-8 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
            <span>
              Our team of trained veterinarians and animal keepers step in to rescue these vulnerable
              individuals. We provide them with long-term foster care at specialized rescue centres,
              nurturing them back to health and ensuring their well-being. Our ultimate goal is to
              prepare them for a life of freedom in the wild –
              their true home.
            </span>
          </v-flex>



          <v-flex xs12 px-2 pt-8 pb-6 pb-sm-4 pb-md-0 style="text-align:justify;font-weight:bold;"
            class="popregular medium">
            <span>
              This is an appeal where we seek your support in providing daily nutrition and medical
              care for these wild animals undergoing rehabilitation with us.
            </span>
          </v-flex>
          <v-flex xs12 px-2 pt-8 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
            <span>
              <span style="font-weight:bold;">
                Join us to be a part of a legacy
              </span>
              where together we&#39;ll witness these young ones grow into
              strong, free-roaming wildlings.
            </span>
          </v-flex>

        </v-layout>
      </v-flex>
     
      <v-flex xs12 md4 pl-0 pl-md-7>
        <v-card elevation="3" v-if="firstCard" class="animate2" id="div2">
          <v-layout wrap justify-center pa-3 pa-sm-0>
            <v-flex xs12 py-3 style="background-color: #f2f2f2; margin-bottom: 1px solid grey">
              <span class="popregular ml">Help our organization by donating today..!</span>
            </v-flex>
            <!-- <v-flex xs12 py-6>
              <a href="https://www.globalgiving.org/donate/9916/wildlife-trust-of-india/">
                <v-btn class="popregular" large color="#ecb338" style="text-transform: none; color: white">
                  Donate on GlobalGiving
                </v-btn>
              </a>
            </v-flex> -->
            <!-- <v-flex xs10>
              <v-layout wrap>
                <v-flex xs5 align-self-center><v-divider></v-divider></v-flex>
                <v-flex xs2 class="popregular ml">Or</v-flex>
                <v-flex xs5 align-self-center><v-divider></v-divider></v-flex>
              </v-layout>
            </v-flex> -->
            <!-- <v-flex xs12 pb-4><span style="
                  color: #555;
                  font-family: montserratbold;
                  font-size: 20px;
                ">Select Country
              </span></v-flex> -->
            <!-- <v-flex xs12 px-3 px-md-12>
              <v-select background-color="#e27725" solo ref="country" dark label="National Of" placeholder="Country"
                class="center4" :items="regions" :rules="[rules.required]" v-model="country" item-text="name"
                item-value="name" item-color="#FF1313">
              </v-select>
            </v-flex> -->

          
            <v-flex xs12 >

              <v-layout wrap justify-center>
                <v-flex xs11 pa-4 style="background-color:#e27725 ;"  >
                  <span class="popregular" style="font-size: large;color: white;">Donate Now</span>

                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs12>
              <v-layout wrap justify-center  class="popregular">
                <v-flex xs12>
                  <v-layout pb-5 pt-5 wrap justify-center style="cursor: pointer;"
                    @click="(secondCard = true), (firstCard = false), login(), donation = BTN1">
                    <v-flex xs12 sm6 md12 lg11 style='background-color: #ffe9d6;'>
                      <v-layout wrap>
                        <v-flex align-self-center xs3 pt-2 pb-2>
                          <span>
                            {{ sign }}
                          </span>
                          <span style="font-size:23px">
                            {{ BTN1 }}
                          </span>
                        </v-flex>
                        <v-flex xs8 pt-4 pb-4 text-left align-self-center>
                          <span style="font-size: 14px;">
                            Will take care of communication costs for one enforcement team member for 2 weeks

                          </span>
                        </v-flex>
                        <v-flex align-self-center pt-7 pb-7  xs1 style="background-color:#f8c087">
                          <v-layout wrap justify-center>
                            <v-flex xs11 >
                             
                            <v-icon  color="white">mdi-chevron-right</v-icon>
                         
                            </v-flex>
                          </v-layout>
                        
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>


                  <v-layout pb-5 wrap justify-center style="cursor: pointer;"
@click="(secondCard = true), (firstCard = false), login(), donation = BTN2">
<v-flex xs12 sm6 md12 lg11 style='background-color: #ffe9d6;'>
  <v-layout wrap>
    <v-flex align-self-center xs3 pt-2 pb-2>
      <span>
        {{ sign }}
      </span>
      <span style="font-size:23px">
        {{ BTN2 }}
      </span>
    </v-flex>
    <v-flex xs8 pt-4 pb-4 text-left align-self-center>
        <span style="font-size: 14px;">
                            Will buy one hidden camera for our enforcement team to gather intelligence

                          </span>
    </v-flex>
    <v-flex align-self-center pt-7 pb-7  xs1 style="background-color:#f8c087">
      <v-layout wrap justify-center>
        <v-flex xs11 >
         
        <v-icon  color="white">mdi-chevron-right</v-icon>
     
        </v-flex>
      </v-layout>
    
    </v-flex>
  </v-layout>
</v-flex>
</v-layout>


<v-layout pb-5 wrap justify-center style="cursor: pointer;"
@click="(secondCard = true), (firstCard = false), login(), donation = BTN3">
<v-flex xs12 sm6 md12 lg11 style='background-color: #ffe9d6;'>
  <v-layout wrap>
    <v-flex align-self-center xs3 pt-2 pb-2>
      <span>
        {{ sign }}
      </span>
      <span style="font-size:23px">
        {{ BTN3 }}
      </span>
    </v-flex>
    <v-flex xs8 pt-4 pb-4 text-left align-self-center>
        <span style="font-size: 14px;">
            Will cover logistical costs for one enforcement staff member for a week


                          </span>
    </v-flex>
    <v-flex align-self-center pt-7 pb-7  xs1 style="background-color:#f8c087">
      <v-layout wrap justify-center>
        <v-flex xs11 >
         
        <v-icon  color="white">mdi-chevron-right</v-icon>
     
        </v-flex>
      </v-layout>
    
    </v-flex>
  </v-layout>
</v-flex>
</v-layout>

<v-layout pb-5 wrap justify-center style="cursor: pointer;"
@click="(secondCard = true), (firstCard = false), login(), donation = BTN4">
<v-flex xs12 sm6 md12 lg11 style='background-color: #ffe9d6;'>
  <v-layout wrap>
    <v-flex align-self-center xs3 pt-2 pb-2>
      <span>
        {{ sign }}
      </span>
      <span style="font-size:23px">
        {{ BTN4 }}
      </span>
    </v-flex>
    <v-flex xs8 pt-4 pb-4 text-left align-self-center>
        <span style="font-size: 14px;">
            Will cover 25% of costs towards a software that help that will help monitor suspects
                            movements.

                          </span>
    </v-flex>
    <v-flex align-self-center pt-7 pb-7  xs1 style="background-color:#f8c087">
      <v-layout wrap justify-center>
        <v-flex xs11 >
         
        <v-icon  color="white">mdi-chevron-right</v-icon>
     
        </v-flex>
      </v-layout>
    
    </v-flex>
  </v-layout>
</v-flex>
</v-layout>
<v-layout pb-5 wrap justify-center style="cursor: pointer;"
@click="(secondCard = true), (firstCard = false), login(), donation = BTN5">
<v-flex xs12 sm6 md12 lg11 style='background-color: #ffe9d6;'>
  <v-layout wrap>
    <v-flex align-self-center xs3 pt-2 pb-2>
      <span>
        {{ sign }}
      </span>
      <span style="font-size:25px">
        {{ BTN5 }}
      </span>
    </v-flex>
    <v-flex xs8 pt-4 pb-4 text-left align-self-center>
        <span style="font-size: 14px;">
            Will cover cost of training for one local staff member to gather intelligence.


                          </span>
    </v-flex>
    <v-flex align-self-center pt-7 pb-7  xs1 style="background-color:#f8c087">
      <v-layout wrap justify-center>
        <v-flex xs11 >
         
        <v-icon  color="white">mdi-chevron-right</v-icon>
     
        </v-flex>
      </v-layout>
    
    </v-flex>
  </v-layout>
</v-flex>
</v-layout>
                  <v-layout pb-5 wrap justify-center>
                    <v-flex xs12 sm6 md12 lg11 style='background-color: #ffe9d6;'>
                      <v-layout wrap justify-end>
                        <v-flex align-self-center xs4 pt-3 pb-3>
                          <v-text-field type="number" id="my_textbox" height="40px" outlined
                            style="background-color:white" hide-details="true" v-model="donation" class="center">
                            <template v-slot:prepend-inner>
                              <v-layout justify-center pt-3>
                                <v-flex xs12 class="align-self-center" style="border-right: 1px dotted grey">
                                  <span style="
                          padding-right:2px;
                          font-family: poppinsregular;
                          font-size: 12px;
                          color: #000;
                        ">{{ Cname + sign }}</span>
                                </v-flex>
                              </v-layout>
                            </template>
                          </v-text-field>
                        </v-flex>
                        <v-flex xs6 pt-3 pb-3 align-self-center>
                          Other Amount
                        </v-flex>
                        <v-flex @click="(secondCard = true), (firstCard = false), login()" xs1 style="background-color:#f8c087">
                          <v-layout wrap >
                            <v-flex xs12 align-self-center pt-6 pr-1>
                                <v-icon  color="white">mdi-chevron-right</v-icon>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>

                </v-flex>
                <!-- <v-flex xs12 sm6 md12 lg6 pt-4 pr-3>
  <v-text-field
                type="number"
                id="my_textbox"
                height="60px"
                outlined
                v-model="donation"
                class="center"
              >
                <template v-slot:prepend-inner>
                  <v-layout justify-center pt-3>
                    <v-flex xs12 
                      
                      class="align-self-center"
                      style="border-right: 1px dotted grey"
                    >
                      <span
                        style="
                          padding-right:2px;
                          font-family: poppinsregular;
                          font-size: 12px;
                          color: #000;
                        "
                        >{{ Cname + sign }}</span
                      >
                    </v-flex>
                  </v-layout>
                </template>
              </v-text-field>
</v-flex>
<v-flex  xs12 sm6 md12  lg5 pt-4>
  <v-btn
                @click="(secondCard = true), (firstCard = false) , login()"
                height="60px"
                color="#e27725"
                dark
                style="font-size: 24px; text-transform: none"
                block
                >Continue <v-icon>mdi-chevron-right</v-icon></v-btn
              >
</v-flex> -->
              </v-layout>
            </v-flex>
            <v-flex xs12>
              <!-- <v-layout wrap pt-8 pa-8 class="popregular">
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    @click="donation = BTN1"
                    outlined
                    block
                    height="60px"
                    color="#e27725"
                    style="font-size: 20px"
                    dark
                    >{{ sign }}{{ BTN1 }}</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    @click="donation = BTN2"
                    height="60px"
                    color="#e27725"
                    dark
                    style="font-size: 20px"
                    block
                    >{{ sign }}{{ BTN2 }}</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    @click="donation = BTN3"
                    height="60px"
                    color="#e27725"
                    dark
                    style="font-size: 20px"
                    block
                    >{{ sign }}{{ BTN3 }}</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    id="my_button"
                    @click="toTextField()"
                    height="60px"
                    color="#e27725"
                    dark
                    style="font-size: 16px; text-transform: none"
                    block
                    >Any other amount</v-btn
                  >
                </v-flex>
              </v-layout> -->
            </v-flex>
            <!-- <v-flex xs12 px-10>
              <v-text-field
                type="number"
                id="my_textbox"
                height="70px"
                outlined
                v-model="donation"
                class="center"
              >
                <template v-slot:prepend-inner>
                  <v-layout justify-center pt-3>
                    <v-flex xs12 
                      
                      class="align-self-center"
                      style="border-right: 1px dotted grey"
                    >
                      <span
                        style="
                          padding-right:2px;
                          font-family: poppinsregular;
                          font-size: 12px;
                          color: #000;
                        "
                        >{{ Cname + sign }}</span
                      >
                    </v-flex>
                  </v-layout>
                </template>
              </v-text-field>
            </v-flex> -->
            <!-- <v-flex xs12 sm6 py-8>
              <v-btn
                @click="(secondCard = true), (firstCard = false) , login() "
                height="60px"
                color="#e27725"
                dark
                style="font-size: 24px; text-transform: none"
                block
                >Continue <v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-flex> -->
            <v-flex class="popregular" xs12 py-5 text-center style="background-color: #f2f2f2">
              <span><v-icon small>mdi-lock</v-icon> </span>
              <span>Secure Donation</span>
            </v-flex>
          </v-layout>
        </v-card>

        <v-card elevation="3" v-if="secondCard">
          <v-form ref="file">
            <v-layout wrap justify-center pa-3 class="animate" id="div1">
              <v-flex xs12>
                <v-layout wrap style="background-color: #f2f2f2">
                  <v-flex xs1 align-self-center style="background-color: #f2f2f2">
                    <v-icon @click="firstCard = true, secondCard = false">mdi-chevron-left</v-icon>
                  </v-flex>
                  <v-flex xs10 py-3 style="
                      background-color: #f2f2f2;
                      margin-bottom: 1px solid grey;
                    ">
                    <span class="popregular ml">Add Your Information.</span>
                  </v-flex>
                  <v-flex xs1 py-3>
                    <v-spacer></v-spacer>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 py-6 text-center style="color: grey">
                <span class="popregular " style="font-size:20px">Who's giving today?</span>
                <br />

                <i>
                  <span class="popregular">We’ll never share this information with anyone</span></i>
              </v-flex>
              <v-flex xs12 px-5>
                <v-text-field class="center2" type="number" ref="amount" color="#828282" placeholder="Amount"
                  label="Amount" :prepend-inner-icon="sign" outlined rounded :rules="[rules.required]"
                  v-model="donation"></v-text-field>
              </v-flex>
              <v-flex xs12 px-5>
                <v-text-field ref="firstname" color="#828282" prepend-inner-icon="mdi-account" label="First Name" rounded
                  outlined class="center2" :rules="[rules.required]" v-model="firstname"></v-text-field>
              </v-flex>
              <v-flex xs12 px-5>
                <v-text-field ref="lastname" color="#828282" prepend-inner-icon="mdi-account" label="Last Name" rounded
                  outlined class="center2" :rules="[rules.required]" v-model="lastname"></v-text-field>
              </v-flex>
              <v-flex xs12 px-5>
                <v-text-field ref="email" color="#828282" prepend-inner-icon="mdi-email" outlined rounded class="center2"
                  label="Email" :rules="[rules.email]" v-model="email"></v-text-field>
              </v-flex>
              <template v-if="taxExemption == true">
                <v-flex xs12 px-5>
                  <v-text-field color="#828282" prepend-inner-icon="mdi-pencil" outlined rounded class="center2"
                    label="Address" :rules="[rules.required]" v-model="Newaddress"></v-text-field>
                </v-flex>
                <v-flex xs12 px-5>
                  <v-text-field color="#828282" prepend-inner-icon="mdi-pen" outlined rounded class="center2" label="City"
                    :rules="[rules.required]" v-model="Newcity"></v-text-field>
                </v-flex>
                <v-flex xs12 px-5>
                  <v-text-field color="#828282" prepend-inner-icon="mdi-pen" outlined rounded class="center2"
                    label="Pincode" :rules="[rules.required]" v-model="Newpincode"></v-text-field>
                </v-flex>
                <v-flex xs12 px-5>
                  <v-text-field color="#828282" prepend-inner-icon="mdi-pen" outlined rounded class="center2"
                    label="Pan Number" :rules="[rules.required]" v-model="Newpannumber"></v-text-field>
                </v-flex>
              </template>
              <v-flex xs12 px-5 pt-2>
                <v-checkbox v-model="taxExemption" hide-details="" class="LBL" :value="true"
                  label="I wish to claim tax exemption against this donation"></v-checkbox>
              </v-flex>

              <v-flex xs12 px-5>
                <v-checkbox v-model="isanonymous" hide-details="" class="LBL" :value="true"
                  label="Make my donation anonymous "></v-checkbox>
              </v-flex>

              <v-flex xs12 px-5>
                <v-checkbox v-model="subscribe" hide-details="" class="LBL" :value="true" label="
                Subscribe to our newsletter for inspiring stories from our
                projects"></v-checkbox>
              </v-flex>

              <v-flex xs12 sm6 py-8>
                <v-btn @click="validate()" height="60px" color="#e27725" dark style="
                    font-size: 20px;
                    text-transform: none;
                    font-family: poppinsregular;
                  " block>Donate Now
                </v-btn>
              </v-flex>

              <v-flex class="popregular" xs12 py-5 text-center style="background-color: #f2f2f2">
                <span><v-icon small>mdi-lock</v-icon> </span>
                <span>Secure Donation</span>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-flex>
      <v-flex xs12 pt-10 id="faq-section">
        <v-layout wrap justify-center>
            <v-flex xs11 text-left pl-3>
            <span style="font-weight:bold;" class="popregular ml">Frequently asked question </span>
          </v-flex>
         
          <v-flex xs11 text-left pt-2 pl-3>
              <v-layout wrap>

<v-flex xs12 pt-3 style="text-align: justify;">
  <template >
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="popregular medium" style="color: black;font-weight: bold;font-size: 16px;">
          What is Wildlife Rehabilitation?
        </v-expansion-panel-header>
        <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;">
          Wildlife rehabilitation is the scientific process of providing adequate aid to injured, orphaned,
          or distressed wild animals such that they can survive upon
          release into their native habitat.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </template>
</v-flex>
<v-flex xs12 pt-5 style="text-align: justify;">
  <template>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="popregular medium" 
style="color: black;font-weight: bold;font-size: 16px;"
        
        >
          Why Rehabilitate?
        </v-expansion-panel-header>
        <v-expansion-panel-content class="popregular medium" 
style="color: black;font-size: 14px;"
        
        >
          Rehabilitating wild animals not only transforms individual tragedies into success stories but also
          adds to the overall wild population of India. For species like
          elephants, rhinos, and bears, facing extinction, rehabilitation becomes a crucial strategy for
          bolstering their numbers in the wild.
          Wild species play a unique and significant part in maintaining the ecosystem. By reintegrating
          individuals into their native habitat we also ensure the health
          of the ecosystem they are a part of.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </template>
</v-flex>
<v-flex xs12 pt-5 style="text-align: justify;">
  <template>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="popregular medium" 
style="color: black;font-weight: bold;font-size: 16px;"
      
        >
          Why can’t we keep the rescued individuals in captivity?
        </v-expansion-panel-header>
        <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;"
>
          At WTI, we firmly believe that instead of a life of misery in captivity, wild animals deserve the
          freedom of the wild, their true home. Captivity deprives wild
          animals of the richness and fulfilment their natural habitats provide. No matter how carefully a
          captive habitat is constructed, it can never fully replicate the
          freedom and complexity of the natural environment essential for their well-being.
          Wild animals held in captivity often lead confused and stressed lives as they struggle with their
          natural instincts in an artificial setting. This disconnect
          prevents them from experiencing the full range of behaviours and interactions vital for their
          physical and psychological health. Captivity therefore, should
          be the last resort, only for cases where the animal is unfit to be released into the wild.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </template>
</v-flex>
<v-flex xs12 pt-5 style="text-align: justify;">
  <template>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="popregular medium" style="color: black;font-weight: bold;font-size: 16px;"
>
          Can a rehabilitated animal survive in the wild?

        </v-expansion-panel-header>
        <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;"
>
          A rehabilitated animal can indeed survive in the wild. For a successful rehabilitation, the animal
          must be in good physical health, free from injuries, diseases,
          or conditions that could impede its ability to survive in the wild. Further, it should have
          learned or re-acquired essential survival skills during rehabilitation,
          such as hunting, foraging, and avoiding predators.
          The release site should provide a habitat that offers adequate food, shelter, and protection from
          threats like predators or human activities. To maintain its
          wild instincts, the animal should not become habituated to humans during rehabilitation, or after
          release. In some cases, post release monitoring, and
          occasionally, supplementary support may be necessary to ensure a successful transition to a wild
          environment.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </template>
</v-flex>
<v-flex xs12 pt-5 style="text-align: justify;">
  <template>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="popregular medium" style="color: black;font-weight: bold;font-size: 16px;"
>
          Why are animals orphaned at such young age?

        </v-expansion-panel-header>
        <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;"
>
          Young animals of different species become orphaned for various reasons. In the case of birds, nest
          falls are the most frequent cause. For species like
          elephants, rhinos or bears, young ones are orphaned and abandoned in the wild due to the tragic
          death of their mothers, often killed by poaching,
          electrocution, or in conflict with people. Sometimes, parents also die due to natural causes such
          as disease, floods, or intra-species fighting.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </template>
</v-flex>
<v-flex xs12 pt-5 style="text-align: justify;">
  <template>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="popregular medium" style="color: black;font-weight: bold;font-size: 16px;"
>
          Rehabilitating wild animals must be fun?

        </v-expansion-panel-header>
        <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;"
>
          No, it isn’t. While it can be intriguing, stimulating, and many times rewarding, it is also
          physically and mentally demanding, emotionally taxing, and requires
          substantial effort. Professional rehabilitators adhere to established criteria, safety standards,
          and government regulations for effective rehabilitation.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </template>
</v-flex>

<v-flex  xs12 pt-5 style="text-align: justify;">
  <template>
      <div >
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="popregular medium" style="color: black;font-weight: bold;font-size: 16px;"
>
          How does WTI rehabilitate wild animals?

        </v-expansion-panel-header>
        <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;"
>
          At WTI, we have developed protocols for the long-term rehabilitation of indigenous wildlife in
          consultation with national and international wildlife
          rehabilitation experts. WTI strictly adheres to existing government rules and regulations
          regarding the handling and release of rescued wildlife. These
          protocols are continuously updated based on the latest research findings and individual case
          information. They encompass principles of nursing and
          husbandry, site selection for rehabilitation, and post-release monitoring (using radio
          transmitters or other tracking mechanisms).
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    </div>
  </template>
</v-flex>

<v-flex xs12 pt-5 style="text-align: justify;">
  <template>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="popregular medium" style="color: black;font-weight: bold;font-size: 16px;"
>
          Who is rehabilitating wildlife at WTI?

        </v-expansion-panel-header>
        <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;"
>
          We have an awesome team of 10 veterinary surgeons and 16 animal keepers who are tirelessly working
          around the clock to rehabilitate and treat orphaned
          and/or injured wild animals.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </template>
</v-flex>
<v-flex xs12 pt-5 style="text-align: justify;">
  <template>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="popregular medium" style="color: black;font-weight: bold;font-size: 16px;"
>
          How will be my contribution utilised?

        </v-expansion-panel-header>
        <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;"
>
          WTI maintains a rigorous financial accountability system to ensure that every donation directly
          supports our on-ground wildlife conservation efforts. Your
          contribution to this appeal will fund various activities related to wildlife rehabilitation,
          including rescue, treatment, nursing, nutrition, medical care, and
          post-release monitoring for a diverse range of species. Through these efforts, we aim to safeguard
          wildlife and ensure they remain #ForeverWild. Your
          contribution is greatly appreciated and will make a significant impact.
          To stay updated on the progress and impact of this initiative and other WTI projects, we encourage
          you to SUBSCRIBE to the WTI Newsletter.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </template>
</v-flex>

</v-layout>
           
          
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>


  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      Newaddress: '',
      Newcity: "",
      Newpincode: "",
      Newpannumber: "",
      donation2: null,
      //inherited varibles from donation Card
      BTN1: 10,
      BTN2: 25,
      BTN3: 50,
      BTN4: 75,
      BTN5: 100,

      sign: "₹",
      Cname: "INR",
      country: "India",
      amount: null,
      firstname: null,
      lastname: null,
      mobile: null,
      email: null,
      address: null,
      city: null,
      state: null,
      postalcode: null,
      panno: null,
      hearaboutus: "",
      comments: null,
      isanonymous: false,
      subscribe: false,
      taxExemption: false,
      symbol: "₹",
      regionssymbol: [],
      message: "",
      resource: null,
      regions: [],
      //end of inherited variables from donationCard
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      donation: null,
      firstCard: true,
      secondCard: false,
      radio: null,
      showSnackBar: false,
      msg: "",
      ServerError: false,
      timeout: 3000,
      appLoading: false,
    };
  },
  watch: {
    country() {
      if (this.country != "India") {
        this.BTN1 = 10;
        this.BTN2 = 25;
        this.BTN3 = 50;
        this.BTN4 = 75;
        this.BTN5 = 100;

        this.sign = "$";
        this.Cname = "USD";
        this.donation = this.BTN1;
        //  console.log("true not india")
      } else {
        this.BTN1 = 10;
        this.BTN2 = 25;
        this.BTN3 = 50;
        this.BTN4 = 75;
        this.BTN5 = 100;
        this.sign = "₹";
        this.Cname = "INR";
        this.donation = this.BTN1;
        //  console.log("false india")
      }
    },
  },
  beforeMount() {
    axios({
      method: "GET",
      url: "/country/all",
    })
      .then((response) => {
        this.regions = response.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    // this.getData();
  },
  methods: {
    navigateToRehabitation() {
      this.$router.push("/rehabitation");
    },
    login() {
      this.$gtag.event('login', { method: 'Google' })
    },
    toTextField() {
      this.donation = null;
      document.getElementById("my_button").onclick = function () {
        document.getElementById("my_textbox").focus();
      };
    },
    validate() {
      if (this.$refs.file.validate()) {
        var don = {};
        this.appLoading = true;
        don["country"] = this.country;
        don["amount"] = this.donation;
        don["firstname"] = this.firstname;
        don["lastname"] = this.lastname;
        // don["donationtype"] = this.$route.query.DonationName;
        don["email"] = this.email;
        don["isanonymous"] = this.isanonymous;
        don["subscribe"] = this.subscribe;
        don["address"] = this.Newaddress;
        don["city"] = this.Newcity;
        // don["pincode"] = this.Newpincode;
        // don["panNumber"] = this.Newpannumber;
        don["taxExemption"] = this.taxExemption;
        don["postalcode"] = this.Newpincode;
        don["panno"] = this.Newpannumber;
        // taxExemption
        axios({
          method: "post",
          url: "/payment/initiate",
          data: don,
        })
          .then((response) => {
            if (response.data.status) {
              localStorage.setItem("currencySymbol", this.symbol);
              this.appLoading = false;
              this.$router.push(
                "/payment/?id=" +
                response.data.id +
                "&country=" +
                this.country +
                "&event=" +
                this.$route.query.DonationName
              );
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch(() => {
            this.appLoading = false;
            this.ServerError = true;
          });
      } else {
        this.showSnackBar = true;
        this.msg = "Please complete your form";
      }
    },
  },
};
</script>
<style  scoped>
.LBL>>>label {
  font-family: poppinsregular !important;
  font-size: 12px !important;

}

.v-text-field--outlined>>>fieldset {
  border-color: grey;
  border-radius: 5px;
}

.v-radio>>>label {
  font-size: 20px;
  font-family: poppinsregular;
  color: black;
}

.center>>>input {
  text-align: center;
  font-family: poppinsregular;
  font-size: 32px;
}

.center2>>>label {
  text-align: center;
  font-family: poppinsregular;
  font-size: 20px;
}

.center4 {
  text-align: center !important;
  font-family: poppinsregular !important;

}

.boldR>>>label {
  font-family: poppinsbold;
}

.animate {
  position: relative;
  animation: mymove 0.25s;
}

.animate2 {
  position: relative;
  animation: mymove2 0.25s;
}

#div2 {
  animation-timing-function: linear;
}

@keyframes mymove2 {
  from {
    left: -200px;
  }

  to {
    left: 0px;
  }
}

@keyframes mymove {
  from {
    right: -500px;
  }

  to {
    right: 0px;
  }
}

#div1 {
  animation-timing-function: linear;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear;
  /* Safari */
  animation: spin 2s linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.v-checkbox>>>label {
  font-family: poppinsregular;
}
</style>