<template>
    <div>
      <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #000">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #000">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center pt-7>
        <v-flex xs12 text-left>
          <v-layout wrap justify-center>
            
            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs12 align-self-center text-center py-4>
                  <span style="font-weight:bold;" class="popregular ml">
                  Testimonials
                  </span>
                </v-flex>
              
              </v-layout>
            </v-flex>
            <v-flex xs12 lg12 py-7 v-if="icons">
              <OwlCarousel
                :autoplay="true"
                :loop="false"
                :nav="true"
                :dots="false"
                :responsive="owlResponsive"
              >
                <template v-for="(item, i) in icons">
                  <v-layout wrap justify-center :key="i">
                    <v-flex xs12 sm11 pl-4  >
                      <v-card outlined>
                        <v-layout wrap pa-5 class="popregular">
                            <v-flex xs12 pt-5 style="font-size:17px;font-weight:bolder"  text-center>
                               {{ item.heading }}
                            </v-flex>
                            <v-flex xs12  pt-3  text-center style="color:#e27725">
                                {{ item.location }}

                            </v-flex>
                            <v-flex xs12 pt-4  pt-3 text-center style="text-align: justify;">
                                {{ item.text }}
                            </v-flex>
                        </v-layout>
                        <!-- <v-img contain height="150px" :src="item.name">
                            <span>{{item.text }}</span>
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#FF6907"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img> -->
                      </v-card>
                    </v-flex>
                  </v-layout>
                </template>
              </OwlCarousel>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        owlResponsive: {
          0: { items: 1, nav: false },
          600: { items: 1, nav: false },
          960: { items: 1, nav: false },
          1264: { items: 1, nav: false },
          2560: { items: 1, nav: false },
        },
        icons: [
        {
  heading: "Sarah Thompson",
  location: "New York, USA",
  text: '"Supporting WTI has been an incredibly rewarding experience. Knowing that my contributions directly impact wildlife conservation efforts gives me a deep sense of happiness and satisfaction. Their transparency and dedication to the cause are commendable. I\'m proud to be a part of their mission and look forward to seeing the positive change they continue to bring."',
},


{
  heading: "John and Emily Martinez",
  location: "London, UK",
  text: '“Me and my brother have been donating to WTI for several years now, and it\'s been a remarkable journey. Witnessing the successful rehabilitation and release of animals back into their natural habitat is purely heartwarming. WTI’s commitment to wildlife conservation is evident in every project they undertake. We wholeheartedly support their work and encourage others to join us in making a difference.”',
},


{
  heading: "Rajesh Kumar",
  location: "Mumbai, India",
  text: '“Supporting WTI is not just about contributing funds; it\'s about being a part of a larger conservation family. I really appreciate the regular updates I receive that keep me informed of the positive impact my donations have had on wildlife and communities. This is truly inspiring and it gives me a sense of fulfillment, which I really value. WTI has my unwavering support.”',
},


        ],
      };
    },
  };
  </script>
  